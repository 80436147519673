import { capitalCase } from 'change-case';

import { RegistryPackage, RegistryPackageType, RegistrySearchResult } from '@/graphql/upbound-graphql';
import { CONFIG_REGISTRY_HOST } from '@/utils/constants/config';

function getIsFamilyProvider(packageData: RegistryPackage | RegistrySearchResult) {
  const { repositoryId, familyRepositoryId } = packageData;
  return !!familyRepositoryId && repositoryId === familyRepositoryId;
}

function getBaseCodeBlock(data: RegistryPackage) {
  const packageUrl = `${CONFIG_REGISTRY_HOST}/${data.account}/${data.name}:${data.version}`;
  const apiVersion =
    data.packageType === RegistryPackageType.Function ? 'pkg.crossplane.io/v1beta1' : 'pkg.crossplane.io/v1';

  return `apiVersion: ${apiVersion}
kind: ${capitalCase(data.packageType)}
metadata:
  name: ${data.name}
spec:
  package: ${packageUrl}`;
}

function getPrivateCodeBlock() {
  return `
  packagePullSecrets:
    - name: package-pull-secret`;
}

function getCodeBlock(data: RegistryPackage) {
  const base = getBaseCodeBlock(data);
  if (data.public === false) {
    return base + getPrivateCodeBlock();
  }

  return base;
}

export { getIsFamilyProvider, getCodeBlock };
